<template>
    <div>KUNDENAKTE
        <h1>Kunden</h1>
        <h>KUNDENAKTE HIER</h>
        <TableFullOptions />
    </div>    
</template>
<script>
export default {
name: 'Kundenakte',
    computed:{
        main() {
            return this.$route.path === '/Kundenakte';
        }
    }
}
</script>